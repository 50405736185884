.aboutme {
    height: 100%;
    background-color: #f1f2f3;
    opacity: 0.9;

    .section {
        width: 90%;
        color: black;
        font-size: 18px;
        margin: auto;
        line-height: 28px;
        padding-top: 60px;

        .heart {
            &::after {
                content: '\2764';
                color: red;
            }
        }

        .country {
            &::after {
                content: '\1F1EE\1F1F3';
            }
        }
    }
}