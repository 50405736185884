.static-header {
    z-index: 15;
    height: 50px;
    width: 100%;
    background: #fffcfcc9;
    position: absolute;
    top: 0;
    left: 0;

    .logo-holder {
        width: 150px;
        margin: auto;
    
        img {
            height: 50px;
        }
    }

    a {
        position: absolute;
        top: 0;
        right: 30px;
    }
}