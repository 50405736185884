.experience {
    min-height: 100%;
    background-color: #ffb98994;

    .section {
        width: 90%;
        color: black;
        font-size: 18px;
        margin: auto;
        line-height: 28px;
        padding-top: 60px;
        padding-bottom: 1px;

        .company-container {
            background: antiquewhite;
            padding: 15px;
            border-radius: 15px;
            box-shadow: 1px 1px 15px grey;
            margin-bottom: 30px;

            .company-name {
                font-size: 20px;
                font-weight: bold;

                &::before {
                    content: "\1F3E2";
                }
            }

            .position-duration {
                display: flex;
                font-size: 14px;
                margin-left: 15px;

                .duration {
                    font-style: italic;
                    margin-left: 5px;
                }
            }

            .projects {
                .project-responsibilities {
                    font-size: 16px;
                }
                .project-technologies {
                    font-size: 16px;
                    font-style: italic;
                }
            }

            .projects-list {
                .client-company {
                    background: white;
                    border-radius: 15px;
                    padding: 10px;

                    .client-name {
                        font-size: 19px;
                        font-weight: bold;
                        font-style: italic;
                    }
                }
            }
        }
    }
}
