.Vacation {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    font-family: sans-serif;
    text-align: center;
    padding-top: 40px;
    width: 100%;
    height: 100vh;
    background-image: url('../../assets/dubai1.jpg');
    background-size: cover;
    background-position-x: center;
  }
  
  .time {
    font-size: 32px;
  }

  @media (max-width: 800px) {
    .Vacation {
      flex-direction: column;
      align-items: center;
    }
  }