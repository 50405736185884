@import-normalize; /* bring in normalize.css styles */

html, body {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.i-am-bold {
  font-weight: bold;
}

.m-t-m {
  margin-top: 20px;
}

.m-b-s {
  margin-bottom: 10px;
}

.p-l-s {
  padding-left: 10px;
}

.section {
  .title {
      font-size: 40px;
      text-align: center;
      margin-bottom: 20px;
  }
}

.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@media only screen and (max-width: 1000px) {
  .section {
      width: 95% !important;
  }

  .salutation-container {
      .salutation {
          width: 95% !important;
          font-size: 60px !important;
      }
  }
}

@media only screen and (max-width: 550px) {
  .salutation-container {
      .salutation {
          padding: 20px !important;
          font-size: 40px !important;
      }
  }
}
