.content {
  background: linear-gradient(skyblue, #ffffff);
  width: 100vw;
  min-height: 100vh;
  overflow: hidden;

  &.moonlight {
    background: linear-gradient(#000000, #0F0F0F); 
  }

  &.evening {
    background: linear-gradient(skyblue 60%, #ff8030);
  }

  &.rainy {
    background-image: linear-gradient(to bottom, #242b2f, #a2b8c5 14%);
  }

  .temprature {
    position: relative;
    font-size: 44px;
    text-align: center;
    top: 45px;
  }

  --thunder-duration: 5s;
  --thunder-delay: 5s;
  
  hr.thunder {
    border: unset;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    animation-name: thunder;
    animation-duration: var(--thunder-duration);
    animation-timing-function: cubic-bezier(1, -1.52, 0, 0.83);
    animation-delay: var(--thunder-delay);
    animation-iteration-count: infinite;
  }
  
  hr:not(.thunder) {
    width: 50px;
    border-color: transparent;
    border-right-color: rgba(255, 255, 255, 0.7);
    border-right-width: 50px;
    position: absolute;
    bottom: 100%;
    transform-origin: 100% 50%;
    animation-name: rain;
    animation-duration: 1s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }
  
  @keyframes rain {
    from {
      transform: rotate(95deg) translateX(0);
    }
    to {
      transform: rotate(95deg) translateX(calc(100vh + 20px));
    }
  }
  
  @keyframes thunder {
    0% {
      background-color: transparent;
    }
    1% {
      background-color: white;
    }
    2% {
      background-color: rgba(255, 255, 255, 0.8);
    }
    8% {
      background-color: transparent;
    }
  }
}

.sun {
  top: 50px;
  left: 50px;
  position: relative;
  border-radius: 50%;
  height: 150px;
  width: 150px;
  background: #ffb000;
  box-shadow: 0 0 20px #c39644, 0 0 60px #e8a01d, 0 0 200px yellow, inset 0 0 80px yellow;
}

.moon {
  top: 50px;
  left: 50px;
  position: relative;
  border-radius: 50%;
  height: 150px;
  width: 150px;
  background: #ffffff;
  box-shadow: 0 0 20px #b0b0b0;

  .crator {
    background-color: #b0b0b063;
    box-shadow: 0 0 20px black;

    &.crator1 {
      position: relative;
      top: 35px;
      left: 40px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
    }
    &.crator2 {
      position: relative;
      top: 75px;
      left: 100px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
    }
    &.crator3 {
      position: relative;
      top: 85px;
      left: 75px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
    }
  }
}

.cloud {
  // display:inline-block;
  background: white;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  position: relative;
  -webkit-filter: blur(6px);
  animation: 25000ms cloudAnimation linear infinite;
  opacity: 0.76;

  &._0 {
    top: 10px;
  }
  &._1 {
    top: 100px;
  }
  &._2 {
    top: 70px;
  }
  &._3 {
    top: 150px;
  }
}
.location-banner {
  position: absolute;
  right: 20px;
  top: 55px;
  padding: 10px;
  background: #f5e7bd;
  border-radius: 10px;
  z-index: 99999;
}
.cloud:before {
  content:"";
  display:inline-block;
  background:white;
  width:100px;
  height:100px; 
  -webkit-filter: blur(3px);
  position:relative;
  border-radius:50%;
  top:-30px;
  left:60px;
}
.cloud:after {
  content:"";
  display:inline-block;
  background:white;
  width:200px;
  height:100px; 
  -webkit-filter: blur(3px);
  position:relative;
  border-radius:50%;
  top:-80px;
  left:70px;
}

@keyframes cloudAnimation {
  0%{
    transform: translate(-200px,0);
  }
  100% {
    transform: translate(1500px,0);
  }
}
    