.home {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    overflow-y: auto;

    .salutation-container {
        width: 100%;
        height: 800px;

        .salutation {
            width: 90%;
            background: #f1f2f3;
            color: Black;
            font-size: 80px;
            font-weight: bold;
            opacity: 0.9;
            border-radius: 25px;
            height: 330px;
            transform: translateY(50%);
            margin: auto;
            padding: 60px;
        }
    }
}
