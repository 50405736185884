.footer {
    // height: 300px;
    background: black;
    color: white;
    padding: 20px;

    .social-link {
        font-size: 20px;

        .social-icon {
            display: inline-block;
            width: 35px;
            height: 35px;
            cursor: pointer;

            &.facebook {
                background: url("../../assets/fb_bnw.svg") no-repeat;
                background-size: 100%;
            }
            &.linkedin {
                background: url("../../assets/linked_bnw.svg") no-repeat;
                background-size: 100%;
            }
            &.instagram {
                background: url("../../assets/insta_bnw.svg") no-repeat;
                background-size: 100%;
            }
        }
    }

    .footer-text {
        text-align: center;
    }
}